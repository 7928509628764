import {FaInstagram} from "react-icons/fa";

export const DETAILS = {
    name: " سفیر سینک",
    shortName: "سفیر سینک",
    footerName: "سفیر سینک",
    description: "",
    shortAbout: "سفیر سینک، یک سایت فروشگاهی آنلاین متخصص در عرضه هود، سینک، گاز، فر و محصولات دیگر\n" +
        "                                    مرتبط با آشپزخانه به صورت انحصاری از نمایندگی ایلیا استیل است. هدف اصلی ما در سفیر\n" +
        "                                    سینک، ایجاد یک تجربه خرید آسوده و رضایت بخش برای شما مشتریان عزیز است و در عین حال،\n" +
        "                                    حمایت از حقوق مصرف‌کننده را در نظر داریم.",
    veryShortAbout: "",
    link: "https://safirsink.ir",
    mobile: ["09302022777"],
    phone: ["02636534327"],
    email: [],
    postalCode: [],
    address: "کرج، فردیس، جاده ملارد، بعد از پل ارتش، پشت فضای سبز، فروشگاه سفیر",
    supportDescription: "",
    mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d6479.631860480681!2d50.990452!3d35.706147!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDQyJzIyLjEiTiA1MMKwNTknMjUuNiJF!5e0!3m2!1sen!2s!4v1709628088774!5m2!1sen!2s",
    socialMedia: [
        {
            title: 'واتس اپ',
            value: '09302022777',
            name: 'whatsapp',
            link: 'https://api.whatsapp.com/send/?phone=989302022777&text&app_absent=0',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: true
        },
        {
            title: 'تلگرام',
            value: 'yragh_safir1',
            name: 'telegram',
            link: 'https://t.me/yragh_safir1',
            icon: <i className="lab la-telegram-plane"/>,
            isPrimary: true
        },
        {
            title: 'اینستاگرام',
            value: 'yragh_safir ',
            name: 'instagram',
            link: "https://instagram.com/yragh_safir ",
            icon: <FaInstagram/>,
            isPrimary: true
        },
    ],
    services: [],
    brands: [],
    enamad: {
        link: "https://trustseal.enamad.ir/?id=471203&Code=jRFPnGuzMFqKKQ1JcyA9WpArI8SMs1He",
        src: "https://trustseal.enamad.ir/logo.aspx?id=471203&Code=jRFPnGuzMFqKKQ1JcyA9WpArI8SMs1He",
        code: "jRFPnGuzMFqKKQ1JcyA9WpArI8SMs1He"
    },
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
